<template>
  <div style="text-align: center; padding-top: 50px">
    <van-loading v-show="showLoading" size="24px">用户授权中...</van-loading>
  </div>
</template>
<script>
import "./index.less";
import { getQueryString, getQueryString2 } from "@/utils/utils";
import { Loading, Toast } from "vant";
export default {
  components: {
    [Loading.name]: Loading,
    [Toast.name]: Toast,
  },
  data() {
    return {
      code: "",
      TOKEN: "",
      showLoading: true,
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    // 授权获取code
    getCode() {
      const that = this;
      var local = "http://kinderGather.whsedu.cn/#/AuthorityForSignIn";
      var appid = "wx0826afb089d6465d";
      this.code = getQueryString("code");
      if (!this.code || this.code == null || this.code === "") {
        window.localStorage.setItem(
          "ResearchActivityID",
          getQueryString2("researchActivityID")
        );
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
      } else {
        that.$axios
          .post("/api/WeChat/OAuthForAsset", {
            Code: this.code,
          })
          .then((res) => {
            this.showLoading = false;
            if (res.code === 200) {
              // 需要得到的用户信息存起来，注册的时候会用到，未注册的调往注册
              const data = res.data;
              if (data.UserType != 2) {
                Toast.fail("非职工，扫码无效");
              } else {
                window.localStorage.setItem("UserType", data.UserType);
                window.localStorage.setItem("TOKEN", data.Token);
                if (data.UserID) {
                  window.localStorage.setItem("UserID", data.UserID);
                }
                this.SignIn();
              }
            } else {
              this.showLoading = false;
              window.localStorage.clear();
              Toast.fail(res.data || res.msg || "请求失败");
            }
          });
      }
    },
    SignIn() {
      const that = this;
      that.$axios
        .post("/api/ResearchActivity/SignIn", {
          ID: window.localStorage.getItem("ResearchActivityID"),
        })
        .then((res) => {
          this.showLoading = false;
          if (res.code === 200) {
            Toast.success(res.msg || "签到成功");
          } else {
            Toast.fail(res.data || res.msg || "请求失败");
          }
        });
    },
  },
};
</script>
